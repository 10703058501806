import { Lead } from 'models/Leads';
import { useTranslation } from 'react-i18next';
import { AirbnbAlterationType } from 'components/domain/airbnbAlterationRequest/modal/AirbnbAlteration.types';
import { LeadListTag } from './LeadListTag.styles';

const LeadListItemAlterationRequestedTag = ({ lead }: { lead: Lead }) => {
  const { t } = useTranslation();
  const { airbnbAlteration } = lead;

  if (!airbnbAlteration) {
    return null;
  }

  if (airbnbAlteration?.type === AirbnbAlterationType.GUEST) {
    return (
      <LeadListTag
        backgroundColor="#FF7254"
        data-testid="lead-alteration-requested-tag"
      >
        {t('componentLead.statusIndicator.alterationRequested')}
      </LeadListTag>
    );
  }

  return null;
};

export default LeadListItemAlterationRequestedTag;
