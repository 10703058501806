import { Lead } from 'models/Leads';
import { useTranslation } from 'react-i18next';
import { AirbnbAlterationType } from 'components/domain/airbnbAlterationRequest/modal/AirbnbAlteration.types';
import theme from 'styles/theme';
import { LeadListTag } from './LeadListTag.styles';

const LeadListItemPendingAlterationTag = ({ lead }: { lead: Lead }) => {
  const { t } = useTranslation();
  const { airbnbAlteration } = lead;

  if (!airbnbAlteration) {
    return null;
  }

  if (airbnbAlteration?.type === AirbnbAlterationType.HOST) {
    return (
      <LeadListTag
        backgroundColor={theme.colors.hostfullyYellow}
        data-testid="lead-pending-alteration-tag"
      >
        {t('componentLead.statusIndicator.pendingAlteration')}
      </LeadListTag>
    );
  }

  return null;
};

export default LeadListItemPendingAlterationTag;
