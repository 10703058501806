import { useTranslation } from 'react-i18next';
import { Lead } from 'models/Leads';
import useOpenAirbnbAlterationRequestModal from 'components/domain/airbnbAlterationRequest/useOpenAirbnbAlterationRequestModal';
import { AirbnbAlterationType } from 'components/domain/airbnbAlterationRequest/modal/AirbnbAlteration.types';
import { LeadViewButton } from './LeadListItemViewButton.styles';

const LeadListItemPendingAlterationButton = ({ lead }: { lead: Lead }) => {
  const { t } = useTranslation();
  const { updateAirbnbAlterationRequestModal } =
    useOpenAirbnbAlterationRequestModal();

  const { airbnbAlteration } = lead;

  if (!airbnbAlteration) {
    return null;
  }

  // @ts-expect-error TS7006 [STRICT-MIGRATION] Temporarily suppressing strict type checking - should be fixed when this code is next modified
  const onClick = (e) => {
    e.preventDefault();
    updateAirbnbAlterationRequestModal(lead);
    e.stopPropagation();
  };

  if (airbnbAlteration?.type === AirbnbAlterationType.HOST) {
    return (
      <LeadViewButton
        className="lead-pending-alteration-button"
        data-testid="lead-pending-alteration-button"
        onClick={onClick}
      >
        {t('componentLead.details.reviewAlteration')}
      </LeadViewButton>
    );
  }

  return null;
};

export default LeadListItemPendingAlterationButton;
